import Cleave from "cleave.js";

let simpleDatePickerWidgetsAreInitialized = false;

document.addEventListener("DOMContentLoaded", () => {
  if (!simpleDatePickerWidgetsAreInitialized) {
    document.querySelectorAll(".simple_date_picker").forEach((el) => {
      // eslint-disable-next-line no-new
      new Cleave(el, {
        date: true,
        delimiter: "-",
        datePattern: ["Y", "m", "d"],
      });
    });
    simpleDatePickerWidgetsAreInitialized = true;
  }
});
